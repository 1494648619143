import React, { useState } from 'react'
import { useLocation } from 'react-router'

export const ViewEmbed = () => {
  const location = useLocation();

  const {url} = location.state || {};
  
    
  return (
    <div className='h-[100%] w-[100%]'>
        <iframe className='h-[100%] w-[100%]'  src={`${url}`} allowFullScreen>
        </iframe>
    </div>
  )
}