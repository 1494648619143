import React, { useRef, useState } from 'react'
import { MdFamilyRestroom } from "react-icons/md";
import { FaChildren } from "react-icons/fa6";
import { useNavigate } from 'react-router';
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIndex } from '../state/menuHighlight';



export const Root = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const viewDashboards = ()=> {
        navigate('/ViewAllDashboards')
    }

    useEffect(()=>{
        dispatch(setIndex({index:0}))
    },[])
    
  return (
    <div className=' w-[100%] h-[100%] flex flex-col items-center'>
        <div className='w-[100%] h-[100%] p-2 flex flex-col items-center justify-center'>
            
            <div className='h-[50%] mt-3 shadow-2xl w-[90%] flex flex-col justify-evenly items-center'>
                <p className='text-sm md:text-2xl'>Propel Health Public Dashboard Service</p>
                <button onClick={()=>viewDashboards()} className='text-white bg-[#1C1C1C] rounded-2xl shadow-2xl w-[150px] h-[50px] transform transition-transform duration-200 hover:scale-110'>View Dashboards</button>
            </div>
            
        </div>
        
    </div>
  )
}

