import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./alldata"
import { combineReducers } from "@reduxjs/toolkit";
import highlightIndex from './menuHighlight'


import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key:"root",
    version:1,
    storage:storage
}

const combined_reducers = combineReducers({
        alldata:dataReducer,
        highlight:highlightIndex,
})

const persistedReducer = persistReducer(persistConfig,combined_reducers);

export const store =  configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck:{
            ignoreActions: [FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER]
        }
    })
})

export const persistor = persistStore(store)