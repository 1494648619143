import {createSlice} from '@reduxjs/toolkit'

const highlightIndex = createSlice({
    name:"highlight",
    initialState:{
        index:0
    },
    reducers:{
        setIndex:(state,action)=>{
            state.index = action.payload.index
        },
    }
})

export const {setIndex} = highlightIndex.actions
export default highlightIndex.reducer