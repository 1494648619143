import {createSlice} from '@reduxjs/toolkit'

const AllData = createSlice({
    name:"alldata",
    initialState:{
        data:null
    },
    reducers:{
        loadData:(state,action)=>{
            console.log(action)
            console.log("consoled insode the store")
            state.data = action.payload
            console.log(state.data)
        }
    }
})

export const {loadData} = AllData.actions
export default AllData.reducer