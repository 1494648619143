import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

import {BsArrowLeftShort,BsSearch} from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";


export const Navbar = ({open,setOpen,highlight}) => {
    //const [open,setOpen] = useState(true);
    const [menu,setMenu] = useState(0);
    const highlightIndex = useSelector((state)=>state.highlight.index)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const LinkOnClick = (index) => {
        console.log('link clicked')
    }

    const Menus = [
        {title:"Home",path:'/',icon:<FaHome/>,spacing:false},
        /*{title:"All dashboards",path:'/ViewAllDashboards', icon:<FaMapLocationDot/>,spacing:false},*/
        /*{title:"Manage dashboards",path:'/ManageDashboards',icon:<MdManageAccounts/>,spacing:false},*/
        /*{title:"History",icon:<MdHistory></MdHistory>,component:<TableauDashboard name={"Tableau Dashboard"}/>,spacing:false},*/
       /* {title:"Spatial Report",icon:<FaMapLocationDot></FaMapLocationDot>,component:<FPmain name={"Spatial"}/>,spacing:false},*/
       /* {title:"PowerBI Dashboard",icon:<HiOutlineDocumentReport></HiOutlineDocumentReport>,component:<Dashboard name={"PowerBI Dashboard"}/>,spacing:false},*/
        /*{title:"Tableau Dashboard",icon:<HiOutlineDocumentReport></HiOutlineDocumentReport>,component:<TableauDashboard name={"Tableau Dashboard"}/>,spacing:false},*/
        /*{title:"Masterlist",path:'/ViewMasterList',icon:<FaRegListAlt ></FaRegListAlt >,spacing:false},*/
        
    ]

    useEffect(()=>{
    })

  return (
        <div className={`xs:fixed xs:z-50 md:relative top-0 w-[50%] xs:h-screen  md:z-0 ${open?"xs:w-[90%]":"xs:w-[0%]"}  bg-[#1C1C1C] md:h-screen md:p-5 md:pt-8 ${open?"md:w-[15%]":"md:w-[5%]"} duration-300 relative`}>
            {<BsArrowLeftShort className={`bg-white
            text-precision text-3xl rounded-full 
            hidden md:block
            absolute -right-3 top-9 cursor-pointer border border-precision ${!open && "rotate-180"} 
            `} onClick={()=>setOpen(!open)}/>}
            <div>
                <h3 className={`${!open && "scale-0"} font-roboto duration-300 text-white  text-sm`}>palladium</h3>
            </div>
            <div className={`flex items-center rounded-md mt-6 ${!open?"px-2.5":"px-0"} py-2 bg-slate-500`}>
                <BsSearch className={`text-white cursor-pointer ${open && "mr-2"} text-lg block float-left mr-2`}/>
                <input type='search' placeholder='Search' className={`text-base bg-transparent w-full text-white focus:outline-none ${!open && "hidden"}`}></input>
            </div>
            <ul className='pt-2'>
                {Menus.map((menu,index)=>
                    <Link onClick={(e)=>LinkOnClick(index)}to={menu.path} key={index} className={`${index === highlightIndex?`bg-slate-500`:null} text-gray-300 cursor-pointer gap-x-4 text-sm flex p-2 items-center hover:bg-slate-500 rounded-md ${menu.spacing?"mb-9":"mb-2"}`}>
                        <span className='text-md block float-left'>
                            {menu.icon}
                        </span>
                        <span className={`text-sm font-medium flex-1 duration-300 ${!open && "hidden"}`}>{menu.title}</span>
                    </Link>
                 
                )}
            </ul>
        </div>
    
    
  )
}