import React, { useState } from 'react'
import { useLocation } from 'react-router'

export const TableauDashboard = () => {
  const location = useLocation();

  const {url} = location.state || {};
  
  const [reportURL,setReportURL] = useState()
  const[reportName,setReportName] = useState()
    
  return (
    <div className='h-[100%] w-[100%]'>
        <tableau-viz className='h-[100%] w-[100%]' id="tableauViz" src={`${url}`} toolbar="bottom" hide-tabs>
        </tableau-viz>
    </div>
  )
}
