import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import { MdAddChart } from "react-icons/md";
import {BsArrowLeftShort,BsSearch} from 'react-icons/bs';
import {DateTime} from 'luxon'
import { LuArrowRightToLine } from "react-icons/lu";
import { LuArrowLeftToLine } from "react-icons/lu";
import { FiArrowLeft } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { setIndex } from '../state/menuHighlight';


export const AllDashboards = () => {

const navigate = useNavigate()
const dashboards = useSelector((state)=>state.alldata.data.dashboards.filter((d)=>d.access===2));

const dispatch = useDispatch()
const [filterText,setFilter] = useState(null);
const [filteredData,setFilteredData] = useState(dashboards);
const [displayData, setDisplayData] = useState([]);

const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 8; // Adjust as needed

const viewReport = (d)=>{

    if(d.type === 1){
        navigate('/ViewTableauDashboard',{state : {url:d.url}})
    }else if (d.type != 1) {
        navigate('/ViewEmbed',{state : {url:d.url}})
    }
    //setReportData(null)
    //setReportData(d)
    //reportRef.current.toggleState()
}



useEffect(()=>{
    let data = dashboards;
    dispatch(setIndex({index:1}))
    //when loading, check if the filterText is set and if set, execute the following
    if (filterText) {
        const lowerCaseFilter = filterText.toLowerCase();
        data = dashboards.filter((item) =>JSON.stringify(item).toLowerCase().includes(lowerCaseFilter));
    }

    setFilteredData(data)

    //calculate the startindex
    const startIndex = (currentPage -1) * itemsPerPage;
    const paginatedData = data.slice(startIndex,startIndex + itemsPerPage);
    setDisplayData(paginatedData)


    
},[dashboards, filterText, currentPage])


// Handle text input for filtering
const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentPage(1); // Reset to first page on new filter
  };

// Pagination control functions
const maxPage = Math.ceil(filteredData.length / itemsPerPage); // Total pages based on filtered data length

const goToNextPage = () => {
    const first_argument = currentPage + 1;
    const pageNumber = Math.min(first_argument, Math.ceil(filteredData.length / itemsPerPage))
    setCurrentPage(pageNumber);
    
}
//const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
const goToFirstPage = () => setCurrentPage(1);

const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

const goToLastPage = () => setCurrentPage(maxPage);


  return (
        <>
        <div className='w-[100%] h-[90%] pt-2 flex self-center justify-center shadow-2xl flex-col w3-container' >
            <div className='w-100%  flex justify-between items-center font-medium'>
                <div  className='transform transition-transform duration-200 hover:scale-110 w-[20%] h-[80%] pl-2 flex justify-center cursor-pointer items-center shadow-[0_10px_20px_rgba(0,0,0,0.8)]'>
                    <span className='hidden mr-2 text-sm'>Dashboards</span>
                    <span >Dashboards</span>
                </div>
                {
                <div className={`self-center w-[30%] flex items-center rounded-md mt-6 px-4 py-2 bg-black`}>
                    <BsSearch className={`text-white cursor-pointer text-lg block float-left mr-2`}/>
                    <input value={filterText} onChange={handleFilterChange} type='search' placeholder='Search...' className={`text-base bg-transparent w-full text-white focus:outline-none`}></input>
                </div>}
            </div>
            {
                <div className='flex flex-col h-[100%] w-[100%] md:hidden'>
                    {displayData && displayData.map((d)=>
                        <div className='shadow-[0_10px_20px_rgba(0,0,0,0.3)] flex flex-col items-center justify-center  h-[30%] w-[100%] cursor-pointer'>
                            <h1>{d.name}</h1>
                            <h1>Published {DateTime.fromRFC2822(d.published).toLocaleString(DateTime.DATE_MED)}</h1>
                            <h1>Type: {d.type===1?`Tableau`:`Web`}</h1>
                            <div className='w-[100%] flex items-center justify-center mt-2'>
                                <button className='text-white bg-[#1C1C1C] rounded-2xl shadow-2xl w-[20%] h-[50px] transform transition-transform duration-200 hover:scale-110' onClick={(e)=>viewReport(d)}>View</button>
                                </div>
                            
                        </div>
                    
                )}
                {
                    <div className='flex items-center justify-center mt-2'>
                        <button onClick={goToFirstPage} disabled={currentPage === 1} className='cursor-pointer items-center flex flex-col w-[20%] text-white bg-[#1C1C1C] rounded-2xl shadow-2xl h-[60%] transform transition-transform duration-200 hover:scale-110'  >First <LuArrowLeftToLine/></button>
                        <button onClick={goToPreviousPage} disabled={currentPage === 1} className='cursor-pointer items-center flex flex-col text-white bg-[#1C1C1C] rounded-2xl shadow-2xl w-[20%] h-[60%] transform transition-transform duration-200 hover:scale-110' >Previous<FiArrowLeft/></button>
                        <button onClick={goToNextPage} disabled={currentPage === maxPage} className='cursor-pointer items-center flex flex-col text-white bg-[#1C1C1C] rounded-2xl shadow-2xl w-[20%] h-[60%] transform transition-transform duration-200 hover:scale-110' >Next<FiArrowRight/></button>
                        <button onClick={goToLastPage} disabled={currentPage === maxPage} className='cursor-pointer items-center flex flex-col text-white bg-[#1C1C1C] rounded-2xl shadow-2xl w-[20%] h-[60%] transform transition-transform duration-200 hover:scale-110' >Last<LuArrowRightToLine /></button>
                    </div>
                }
                <div className='w-[100%] flex justify-center'>
                    <span>Page {currentPage} or {maxPage}</span>
                </div>
                
                </div>
            }
            {
            <div className='hidden md:block w-[100%] h-[80%]'>
                {
                    <table id='datatable'>
                    <thead>
                        <th>Name</th>
                        <th>Published</th>
                        <th>Type</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {displayData && displayData.map((d) => (
                            <tr>
                                <td>{d.name}</td>
                                <td>{DateTime.fromRFC2822(d.published).toLocaleString(DateTime.DATE_MED)}</td>
                                <td>{d.type === '1' ? `Tableau` : `Web`}</td>
                                <td>
                                <div className="action-buttons-container">
                                    <button className="action-button" onClick={(e) => viewReport(d)}>
                                        View
                                    </button>
                                </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <div className="pagination-container">
                            <button
                                className="action-button"
                                onClick={goToFirstPage}
                                disabled={currentPage === 1}
                            >
                                First page
                            </button>
                            <button
                                className="action-button"
                                onClick={goToPreviousPage}
                                disabled={currentPage === 1}
                            >
                                Previous page
                            </button>
                            <button
                                className="action-button"
                                onClick={goToNextPage}
                                disabled={currentPage === maxPage}
                            >
                                Next page
                            </button>
                            <button
                                className="action-button"
                                onClick={goToLastPage}
                                disabled={currentPage === maxPage}
                            >
                                Last page
                            </button>
                        </div>
                        <div className="pagination-indicator">
                            <span>Page {currentPage} of {maxPage}</span>
                        </div>`
                    </tfoot>

                </table>
                
                }
                
            </div>
            }
            
        </div>
    </>
  )
}
