import {createSlice} from '@reduxjs/toolkit'

const AuthSlice = createSlice({
    name:"Auth",
    initialState:{
        currentUser:null,
        isFetching:false,
        error:false,
        isClearing:false
    },
    reducers: {
        loginStart:(state)=>{
            state.isFetching = true
        },
        loginSuccess:(state,action)=>{
            state.isFetching=false;
            state.currentUser=action.payload
            state.error=false
        },
        loginFailure:(state)=>{
            state.isFetching = false;
            state.error = true
        },
        logoutStart:(state)=>{
            state.isClearing = true
        },
        logoutSuccess:(state)=>{
            state.isClearing=false;
            state.currentUser=null
            state.error = false
        },
        logoutFailure:(state)=>{
            state.isClearing = false;
            state.error = true
        },
    },
});

export const {loginStart,loginSuccess,loginFailure,logoutStart,logoutSuccess,logoutFailure} = AuthSlice.actions
export default AuthSlice.reducer;