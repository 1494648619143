import React, { useEffect } from 'react'
import { Navbar } from './Navbar'
import { useState } from 'react'
import { Navigate, Outlet } from 'react-router'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { logoutSuccess } from '../state/authRedux'
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { CiMenuBurger } from "react-icons/ci";

export const Layout = ({children}) => {

    const [inactiveTimer, setInactiveTimer] = useState(null);
    const navigate = useNavigate();
    const [open,setOpen] = useState(false)
    const highlight = useSelector((state)=>state.highlight.index)
    
  
    return (
        

                <div className='h-[100%] w-[100%] md:flex relative text-sm'>
                    <Navbar open={open} setOpen={setOpen} highlight={highlight}/>
                    <div className={`overflow-y-auto xs:w-[100%] xs:top-0 xs:z-0 xs:h-full xs:relative md:relative md:z-0 text-sm font-semibold ${open?"md:w-[85%]":"md:w-[95%]"}`} >
                        <div className='sticky top-0 flex h-[8%] w-[100%]  p-2 shadow-2xl items-center justify-center'>
                            <div className='w-[30%] h-[100%] pl-4 flex items-center'>
                                <h2></h2>
                            </div>
                            <div className='w-[70%] h-[100%] flex justify-end items-center'>
                            <span onClick={() => setOpen(!open)} className="cursor-pointer block md:hidden"><CiMenuBurger />
                            </span>
                            </div>
                            
                            
                        </div>
                        <Outlet/>
                    </div>
                </div>
    )
}

{/*
    user && user.isAuthenticated ?
    <div className='h-[100%] w-[100%] md:flex relative'>
         <Navbar open={open} setOpen={setOpen} highlight={highlight}/>
         <div className={`xs:w-[100%] xs:top-0 xs:z-0 xs:h-full xs:relative md:relative md:z-0 text-sm font-semibold ${open?"md:w-[85%]":"md:w-[95%]"}`} >
            <Outlet/>
         </div>
    </div>:<Navigate to={`login`}/>*/}
