import {createBrowserRouter,RouterProvider,BrowserRouter,Routes,Route} from 'react-router-dom';
import { useEffect } from "react";
import api from "./API/api";
import { useDispatch } from "react-redux";
import { loadData } from "./state/alldata";
import { Layout } from "./Components/Layout";
import { AllDashboards } from "./Components/AllDashboards";
import { TableauDashboard } from "./Components/Tableau";
import { ViewEmbed } from "./Components/ViewEmbed";
import { Root } from './Components/Root';



function App() {

  const dispatch = useDispatch()

  useEffect(()=>{
      api.get('/').then((response)=>{
        dispatch(loadData(response.data))
      }).catch((reason)=>{console.log(reason)})
  })
  return <BrowserRouter>
  {/* All routed components will be passed as children to Mainlaout*/}
      <Routes>
          <Route element={<Layout/>}>
            {/*<Route path="/home" element={<PlaformStats/>}></Route>*/}
            <Route path="/" element={<Root/>}></Route>
            <Route path="/ViewAllDashboards" element={<AllDashboards/>}></Route>
            <Route path="/ViewTableauDashboard" element={<TableauDashboard/>}></Route>
            <Route path="/ViewEmbed" element={<ViewEmbed/>}></Route>
            {/*<Route path="/ManageDashboards" element={<ManageDashboards/>}></Route>*/}
          </Route>
          
        </Routes>
  </BrowserRouter>
  ;
}

export default App;
